import { ref, computed, onMounted, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute, RouteRecordNormalized } from 'vue-router';
import { ROUTE_NAME } from '@/router';

export default function useCabinetMenuStore({ id, type }: { id: Ref<string>; type: Ref<string> }) {
  const router = useRouter();
  const route = useRoute();
  const rootStore = useStore();
  const company = computed(() => rootStore.getters['cabinet/company']);
  const routes = ref<RouteRecordNormalized[]>([]);

  onMounted(() => {
    routes.value = router.getRoutes().filter((route) => route.meta?.nav);
  });

  return {
    menuItems: computed(() => {
      if (!company.value) {
        return [];
      }
      let values = routes.value.map(
        ({
          name,
          meta: {
            sorting,
            icon,
            label,
            nav: { roles, types, items },
          },
        }) => {
          return {
            label,
            icon,
            sorting,
            to: items?.length ? null : { name, params: { companyId: id.value } },
            types,
            roles,
            expanded: route.name === name || route.name?.toString().startsWith(name?.toString() || ''),
            items: items?.map(({ label, item }: { label: string; item: string }) => ({
              label,
              to: {
                name: item,
                params: { companyId: id.value, item, label },
              },
            })),
          };
        },
      );

      if (company.value.simple === true) {
        values = values.filter((item) => {
          const routeName = item.to?.name?.toString() ?? '';
          return ['drivers', 'balance', 'cars', 'departments'].includes(routeName) === false;
        });
      }

      return values
        .filter(
          ({ types, roles }) =>
            (roles === undefined || roles?.includes(company.value.role)) &&
            (types === undefined || types?.includes(company.value.type?.toLowerCase())),
        )
        .filter((item) => {
          const routeName = item.to?.name?.toString() || '';
          const hasPartners = company.value?.partnersCount > 1;
          const isSubDepartment = Boolean(company.value?.partner?.parent);
          if (isSubDepartment) {
            const notAllowedRoutes = [
              ROUTE_NAME.subdepartments,
              ROUTE_NAME.departments,
              ROUTE_NAME.balance,
              ROUTE_NAME.reports,
            ];
            return !notAllowedRoutes.includes(routeName) || hasPartners;
          }
          if (type.value === 'department') {
            const notAllowedRoutes = [ROUTE_NAME.departments, ROUTE_NAME.balance, ROUTE_NAME.reports];
            return !notAllowedRoutes.includes(routeName) || hasPartners;
          }
          const notAllowedRoutes = [ROUTE_NAME.subdepartments];
          return !notAllowedRoutes.includes(routeName) || hasPartners;
        })
        .filter((item) => {
          const routeName = item.to?.name?.toString();
          return routeName !== 'waybills' || company.value.id === process.env.VUE_APP_SBERLOGISTICS_COMPANY_ID;
        })
        .sort((a, b) => (b.sorting > a.sorting ? -1 : 1));
    }),
  };
}
