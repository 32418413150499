import { graphql } from '@/gql';
import useQuery from '@/helpers/useQuery';
import {
  CreatePaymentMutationVariables,
  GetPaymentProvidersQueryVariables,
  GetPaymentsQueryVariables,
} from '@/gql/graphql';

export async function getPayments(v: GetPaymentsQueryVariables) {
  const q = graphql(`
    query GetPayments($companyId: ID!, $partnerId: ID, $filter: PaymentsQueryParams) {
      cabinet {
        company(id: $companyId, partnerId: $partnerId) {
          payments {
            balance
            needMoney
            count
            cards {
              id
              name
              provider {
                id
                name
              }
            }
            items(filter: $filter) {
              id
              amount
              type
              status
              statusDescription
              transactionId
              provider {
                name
              }
              createdAt
              balance {
                amount
                description
              }
              ... on OnlinePayment {
                pan
              }
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v);
}

export function getPaymentProviders(v: GetPaymentProvidersQueryVariables) {
  const q = graphql(`
    query GetPaymentProviders($companyId: ID) {
      paymentProviders(companyId: $companyId) {
        id
        name
        service
        type
      }
    }
  `);

  return useQuery(q, v);
}

export function createPayment(v: CreatePaymentMutationVariables) {
  const q = graphql(`
    mutation CreatePayment($contractId: ID!, $providerId: ID!, $cardId: ID, $amount: Float!) {
      createPayment(contractId: $contractId, providerId: $providerId, cardId: $cardId, amount: $amount) {
        id
        ... on InvoicePaymentResponse {
          url
          number
        }
        ... on OnlinePaymentResponse {
          url
          auto
        }
      }
    }
  `);

  return useQuery(q, v);
}
